import React from 'react'
import Features, { Feature } from '@/components/home/Features'

const features: Feature[] = [
  {
    icon: '🤝',
    title: 'Collaborer avec des freelances en Nouvelle-Calédonie',
    content:
      'Notre plateforme favorise la collaboration entre les entreprises et les freelances en Nouvelle-Calédonie. Nous travaillons avec des professionnels locaux pour offrir des services adaptés au marché de la région.',
  },
  {
    icon: '🔎',
    title: 'Trouver le bon profil',
    content:
      'La plateforme permet aux entreprises de trouver des freelances qualifiés qui correspondent exactement à leurs besoins et à leurs attentes.',
  },
  {
    icon: '✅',
    title: 'Trouver une mission en freelance',
    content:
      'Nous simplifions la recherche de missions pour les freelances, en leur offrant une liste de missions en cours et en leur permettant de postuler facilement à celles qui correspondent à leurs compétences.',
  },
]

const HomeFeatures = ({ ...props }) => {
  return (
    <Features
      title={'FREELANCES.NC'}
      subtitle={'La raison d’être de la plateforme freelances.nc'}
      features={features}
      {...props}
    />
  )
}

export default HomeFeatures
