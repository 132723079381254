import { Box, Flex, SimpleGrid, Text } from '@chakra-ui/react'
import React from 'react'

export interface Service {
  icon: any
  title: string
  content: string
}

const Services = ({
  title,
  subtitle,
  services,
  ...props
}: {
  title: string
  subtitle: string
  services: Service[]
}) => {
  return (
    <Flex flex={'2'} flexDir="column" alignItems={'center'} {...props}>
      <Text textAlign={'center'} fontSize={'3xl'} fontWeight="extrabold" mb="4">
        {title}
      </Text>
      <Text textAlign={'center'}>{subtitle}</Text>
      <SimpleGrid columns={{ base: 1, md: 3 }} spacing="20px" mt={10} p={{ base: 0, md: 2 }}>
        {services.map((feature) => {
          return (
            <ServiceCard
              key={feature.title}
              icon={feature.icon}
              title={feature.title}
              content={feature.content}
            />
          )
        })}
      </SimpleGrid>
    </Flex>
  )
}

const ServiceCard = ({
  icon,
  title,
  content,
}: {
  icon: string
  title: string
  content: string
}) => {
  return (
    <Flex
      p={6}
      border={'1px'}
      borderRadius={'4px'}
      borderColor={'gray.100'}
      flexDir={{ base: 'row', md: 'column' }}
    >
      <Box my="auto" fontSize="4xl" textColor={'brand.500'}>
        {icon}
      </Box>
      <Flex flexDir={'column'} ml={'10'}>
        <Text mt={4} fontWeight={'extrabold'} fontSize={'xl'}>
          {title}
        </Text>
        <Text mt="4" fontSize={'md'}>
          {content}
        </Text>
      </Flex>
    </Flex>
  )
}

export default Services
