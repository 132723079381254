import { Flex, Text } from '@chakra-ui/react'
import React from 'react'

export interface Feature {
  icon: string
  title: string
  content: string
}

const Features = ({
  title,
  subtitle,
  features,
  ...props
}: {
  title: string
  subtitle: string
  features: Feature[]
}) => {
  return (
    <Flex flex={'2'} flexDir="column" alignItems={'center'} {...props}>
      <Text mb="2" fontSize={'md'} opacity={0.9} fontWeight="bold" color={'#6b7094'}>
        {title}
      </Text>
      <Text fontSize={'3xl'} textAlign={'center'} fontWeight="extrabold" lineHeight={1.2} mb="4">
        {subtitle}
      </Text>
      <Flex flexDir={{ base: 'column', md: 'row' }} gap={20} mt={20}>
        {features.map((feature) => {
          return (
            <FeatureColumn
              key={feature.title}
              icon={feature.icon}
              title={feature.title}
              content={feature.content}
            />
          )
        })}
      </Flex>
    </Flex>
  )
}

const FeatureColumn = ({
  icon,
  title,
  content,
}: {
  icon: string
  title: string
  content: string
}) => {
  return (
    <Flex flex={1} flexDir={'column'}>
      <Text bg="#f6f9fd" rounded={'5px'} fontSize={'45px'} w={'72px'} textAlign={'center'}>
        {icon}
      </Text>
      <Text mt={4} fontWeight={'extrabold'} fontSize={'2xl'}>
        {title}
      </Text>
      <Text mt="4" fontSize={'md'}>
        {content}
      </Text>
    </Flex>
  )
}

export default Features
