import { Flex, SimpleGrid, Text } from '@chakra-ui/react'
import React from 'react'
import { Feature } from '@/components/home/Features'

const items: Feature[] = [
  {
    icon: '👨‍💻',
    title: 'Flexibilité de travail',
    content:
      'Les freelances peuvent travailler à distance ou sur site, selon les besoins de votre entreprise. Cela peut être particulièrement avantageux pour les entreprises qui ont besoin de ressources supplémentaires sans avoir à investir dans de nouveaux employés à temps plein.',
  },
  {
    icon: '🧑‍🎓',
    title: 'Expertise spécialisée',
    content:
      "Travailler avec des freelances vous permet d'engager des professionnels qualifiés pour des projets précis, sans avoir à vous soucier de les maintenir à bord à long terme.",
  },
  {
    icon: '💰',
    title: 'Coûts réduits',
    content:
      "Les freelances facturent généralement des tarifs horaires/journaliers ou des forfaits pour leurs services, ce qui peut être plus économique pour les entreprises qui cherchent à contrôler les coûts de main-d'œuvre.",
  },
  {
    icon: '⚡',
    title: 'Réactivité',
    content:
      "Travailler avec des freelances peut être bénéfique pour les entreprises qui ont besoin d'une réactivité accrue. Les freelances peuvent travailler sur des projets à court terme avec des délais serrés et peuvent s'adapter rapidement aux changements. En travaillant avec des freelances réactifs, les entreprises peuvent bénéficier d'une plus grande flexibilité pour répondre à des demandes de dernière minute ou des projets imprévus.",
  },
]

const Why = ({ ...props }) => {
  return (
    <Flex flex={'2'} flexDir="column" alignItems={'center'} {...props}>
      <Text mb="2" fontSize={'md'} opacity={0.9} fontWeight="bold" color={'#6b7094'}>
        AVANTAGES
      </Text>
      <Text fontSize={'4xl'} fontWeight="extrabold" mb="4" textAlign={'center'}>
        Opter pour un freelance
      </Text>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10} mt={10}>
        {items.map((feature) => {
          return (
            <WhyColumn
              key={feature.title}
              icon={feature.icon}
              title={feature.title}
              content={feature.content}
            />
          )
        })}
      </SimpleGrid>
    </Flex>
  )
}

const WhyColumn = ({ icon, title, content }: { icon: string; title: string; content: string }) => {
  return (
    <Flex flex={1} flexDir={'column'} bg={'#f6f9fd'} py={6} px={10}>
      <Text bg="white" rounded={'5px'} fontSize={'45px'} w={'72px'} textAlign={'center'}>
        {icon}
      </Text>
      <Text mt={4} fontWeight={'extrabold'} fontSize={'2xl'}>
        {title}
      </Text>
      <Text mt="4" fontSize={'md'}>
        {content}
      </Text>
    </Flex>
  )
}

export default Why
