import { Box, Button, Flex, Img, Text } from '@chakra-ui/react'
import React from 'react'
import { useRouter } from 'next/router'
import { routes } from '@/routes'

const Hero = ({ ...props }) => {
  const router = useRouter()

  const clickOnSearchFreelance = () => router.push(routes.searchFreelance)
  const clickOnSearchMissions = () => router.push(routes.searchMissions)
  return (
    <Flex
      alignItems="center"
      w="full"
      minHeight="60vh"
      flexDirection={{ base: 'column', md: 'row' }}
      {...props}
    >
      <Box mr={{ base: '0', md: '6' }} flex={'2'}>
        <Text
          mb="2"
          fontSize={{ base: 'sm', md: 'md' }}
          opacity={0.9}
          fontWeight="bold"
          color={'#6b7094'}
        >
          1ÈRE PLATEFORME CALÉDONIENNE DE FREELANCES
        </Text>
        <Text fontSize={{ base: '4xl', md: '6xl' }} fontWeight="extrabold" lineHeight={1.2} mb="4">
          Trouvez le{' '}
          <Text as="u" color={'brand.500'}>
            meilleur
          </Text>{' '}
          freelance pour votre projet
        </Text>

        <Text mb="6" fontSize={{ base: 'md', md: 'lg' }}>
          Votre projet mérite les meilleurs talents : trouvez-les sur notre plateforme!
        </Text>

        <Flex flexDir={{ base: 'column', md: 'row' }} gap={4}>
          <Button
            colorScheme="brand"
            variant="solid"
            size={{ base: 'md', md: 'lg' }}
            mb={'0'}
            onClick={(e) => clickOnSearchFreelance()}
          >
            Je trouve un freelance
          </Button>
          <Button
            variant="outline"
            size={{ base: 'md', md: 'lg' }}
            mb={'0'}
            onClick={(e) => clickOnSearchMissions()}
          >
            Je cherche une mission
          </Button>
        </Flex>
      </Box>
      <Flex flex={'2'} alignItems="center" justifyContent="center" mt={{ base: 20, md: 0 }}>
        <Img src={'/hero.svg'} alt="hero" w={'70%'} />
      </Flex>
    </Flex>
  )
}

export default Hero
