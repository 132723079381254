import React from 'react'
import { Box, Container } from '@chakra-ui/react'
import Hero from '@/components/home/Hero'
import Why from '@/components/home/Why'
import HomeServices from '@/components/home/HomeServices'
import HomeFeatures from '@/components/home/HomeFeatures'
import Head from 'next/head'

function HomePage() {
  return (
    <Box pb={'148px'}>
      <Head>
        <title>Freelances.nc</title>
        <meta
          name="description"
          content="Trouvez les meilleurs freelances de Nouvelle-Calédonie pour vos projets grâce à notre plateforme de mise en relation dédiée."
        />
      </Head>
      <Container maxW={'container.xl'}>
        <Hero mt={'32px'} />
        <HomeFeatures mt={'148px'} />
        <HomeServices mt={'148px'} />
        <Why mt={'148px'} />
      </Container>
    </Box>
  )
}

export default HomePage
