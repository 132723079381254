import React from 'react'
import { BiCheckShield } from 'react-icons/bi'
import Services, { Service } from '@/components/home/Services'

const services: Service[] = [
  {
    icon: <BiCheckShield />,
    title: 'Data, IT & Tech',
    content: 'Développeurs, Data analystes, Devops, Administrateurs Réseaux...',
  },
  {
    icon: <BiCheckShield />,
    title: 'Marketing & Communication',
    content: 'Consultant SEO, CRM, Traducteurs, Copywriter...',
  },
  {
    icon: <BiCheckShield />,
    title: 'Stratégie & Business',
    content: 'Business developper, consultant en stratégie...',
  },
  {
    icon: <BiCheckShield />,
    title: 'Designer, Son & Image',
    content: 'DA, Graphiste, UXDesigner, Vidéaste, Webdesigner...',
  },
  {
    icon: <BiCheckShield />,
    title: 'Gestion de projet & Coaching',
    content: 'Chef de projet, Coach agiles, Product manager, Scrum master...',
  },
]

const HomeServices = ({ ...props }) => {
  return (
    <Services
      title={'Nos services'}
      subtitle={'Chez Freelances.nc rencontrez des spécialistes de leur domaine'}
      services={services}
      {...props}
    />
  )
}

export default HomeServices
